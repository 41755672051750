'use client'

import type { ReactNode } from 'react'

import cn from 'clsx'
import { HyperLink } from '@/components/hyper-link/hyper-link'
import type { HyperLinkProps } from '@/components/hyper-link/hyper-link'

import { ActivityIndicator } from '@/components/ActivityIndicator/ActivityIndicator'
import { Box } from '@/components/Box/Box'
import type { TextSize } from '@/components/Text/Text'

import css from './Button.module.css'

export type MainVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary'
export type AuxVariant = 'quinary'
type Variant = MainVariant | AuxVariant

type MainSize = 'small' | 'base' | 'large' | 'xl'
type AuxSize = 'xsmall'
type Size = MainSize | AuxSize

type UnneededExtendedTypes =
    | 'children'
    | 'bold'
    | 'size'
    | 'inheritSize'
    | 'borderRadius'
    | 'weight'

// Set the gap space between icons and text (base: 4px, large: 6px, xl: 8px)
type Gap = 'base' | 'large' | 'xLarge'

export type Icon = React.JSXElementConstructor<
    React.SVGProps<SVGSVGElement> & {
        name?: string
        width?: number | string
        height?: number | string
        color?: string
    }
>

type BleedDirection = 'block' | 'inline'

export type CommonProps = {
    gap?: Gap
    textAlign?: 'left' | 'center' | 'right'
    iconAlign?: 'top' | 'center'
    bleed?: boolean | BleedDirection
    disabled?: boolean
}

// If animation is requested, a loading label is required
type WithAnimating = { animating: boolean; loadingLabel: string }
type WithoutAnimating = { animating?: never; loadingLabel?: never }

// When `children` are not provided to the component, only 1 icon is allowed (start or end).
type StartIconType = { startIcon: Icon; children?: never; endIcon?: never }
type EndIconType = { endIcon: Icon; children?: never; startIcon?: never }
type TextAndIconType = {
    endIcon?: Icon
    children: React.ReactNode
    startIcon?: Icon
}
type HyperLinkType = Omit<HyperLinkProps, UnneededExtendedTypes>

// The xsmall size is only allowed to be used with the quinary variant
type MainButtonType = HyperLinkType &
    CommonProps & { variant?: MainVariant; size?: MainSize; slotLayout?: ReactNode }
type AuxiliaryButtonType = HyperLinkType &
    CommonProps & { variant?: AuxVariant; size?: AuxSize; slotLayout?: ReactNode }

export type ButtonProps = (MainButtonType | AuxiliaryButtonType) &
    (StartIconType | EndIconType | TextAndIconType) &
    (WithAnimating | WithoutAnimating)

const sizeToTextSizeMap = new Map<Size, TextSize>([
    ['xsmall', 'minor-xs'],
    ['small', 'minor-sm'],
    ['base', 'minor-base'],
    ['large', 'minor-lg'],
    ['xl', 'minor-xl'],
])

const semiboldVariants = new Set<Variant>(['primary', 'secondary'])

function LoadingIndicator({
    variant,
    size,
    label,
}: {
    variant: Variant
    size: Size
    label?: string
}) {
    const indicatorSize = { xsmall: 18, small: 19.5, base: 21, large: 23.5, xl: 25 }
    const indicatorColor = variant === 'primary' ? 'var(--white)' : 'var(--display-onlight-primary)'

    return (
        <ActivityIndicator
            size={indicatorSize[size]}
            strokeSize={2}
            strokeColor={indicatorColor}
            fillColor="transparent"
            label={label}
        />
    )
}

export function Button({
    size = 'base',
    variant = 'primary',
    disabled = false,
    children,
    startIcon,
    endIcon,
    href,
    gap = 'base',
    bleed,
    textAlign = 'center',
    iconAlign = 'center',
    onClick,
    className,
    animating,
    loadingLabel,
    slotLayout,
    ...rest
}: ButtonProps) {
    // Set size to 'xsmall' & gap to 'xLarge' when using quinary variant
    size = variant === 'quinary' ? 'xsmall' : size
    gap = variant === 'quinary' ? 'xLarge' : gap
    // If variant is tertiary, bleed is true by default, but can be overridden
    bleed = bleed !== undefined ? bleed : variant === 'tertiary'

    const StartIcon = startIcon
    const EndIcon = endIcon
    const textSize = sizeToTextSizeMap.get(size)

    // If there are icons, the text alignment is always left
    const textAlignment = startIcon || endIcon ? 'left' : textAlign

    const classes = cn(
        css.button,
        css[variant],
        css[size],
        css[`${textAlignment}TextAlign`],
        css[`${iconAlign}IconAlign`],
        css[`${gap}Gap`],
        {
            [css.disabled]: disabled,
            [css.iconButton]: (StartIcon || EndIcon) && !children,
            [css.bleedBlock]: bleed === 'block' || bleed === true,
            [css.bleedInline]: bleed === 'inline' || bleed === true,
        },
        className,
    )

    let internalClick = onClick
    if (!href && !internalClick && disabled) {
        internalClick = () => {
            /* noop */
        }
    }

    return (
        <HyperLink
            tag={href ? 'a' : 'button'}
            href={href}
            disabled={disabled}
            onClick={internalClick}
            className={classes}
            size={textSize}
            weight={semiboldVariants.has(variant) ? 'semibold' : undefined}
            {...rest}
        >
            {animating ? (
                <LoadingIndicator variant={variant} size={size} label={loadingLabel} />
            ) : (
                <>
                    {StartIcon && (
                        <Box className={cn(css.iconContainer, css.startIcon)}>
                            <StartIcon />
                        </Box>
                    )}
                    {children && (
                        <Box tag="span" className={css.textWrapper}>
                            {children}
                        </Box>
                    )}
                    {EndIcon && (
                        <Box className={cn(css.iconContainer, css.endIcon)}>
                            <EndIcon />
                        </Box>
                    )}
                    {slotLayout ? slotLayout : null}
                </>
            )}
        </HyperLink>
    )
}
