'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */

import { createRequiredContext } from './create-required-context'

type INextContext = {
    components: {
        // biome-ignore lint/suspicious/noExplicitAny: we don't want to depend on Next types here
        head: any
        // biome-ignore lint/suspicious/noExplicitAny: we don't want to depend on Next types here
        image: any
    }
    cloudinaryAllowedDomains: string[]
}

const [NextContextProvider, useNextContext] = createRequiredContext<INextContext>('NextContext')

export { NextContextProvider, useNextContext }
