import { useEffect, useRef } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'

import { useInNavbarDrawer } from '../navbar-drawer/navbar-drawer-context'

import css from './navbar-open-transition.module.css'

type OpenTransitionProps = {
    children: React.ReactNode
    open?: boolean
}

const animationOptions: KeyframeAnimationOptions = {
    duration: 350,
    easing: 'cubic-bezier(0.2, 0.8, 0.2, 1)',
}

export function NavbarOpenTransition({ open, children }: OpenTransitionProps) {
    const inNavbarDrawer = useInNavbarDrawer()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const element = ref.current
        let animation: Animation

        cancelAnimations(element)

        function handleAnimationEnd() {
            element.style.blockSize = ''
            element.style.overflow = ''

            if (!open) {
                element.style.blockSize = '0'
                element.style.display = 'none'
            }
        }

        function cancelAnimations(element: HTMLDivElement) {
            try {
                const animations = element.getAnimations()
                for (const animation of animations) {
                    animation.cancel()
                    animation.removeEventListener('finish', handleAnimationEnd)
                }
            } catch (err) {
                // ignore
            }
        }

        if (open) {
            element.style.display = 'block'
            element.style.overflow = 'hidden'

            animation = element.animate(
                {
                    blockSize: ['0px', `${element.scrollHeight}px`],
                },
                animationOptions,
            )
        } else {
            animation = element.animate(
                {
                    blockSize: [`${element.offsetHeight}px`, '0px'],
                },
                animationOptions,
            )
        }
        animation.addEventListener('finish', handleAnimationEnd)

        return function openEffectCleanup() {
            cancelAnimations(element)
        }
    }, [open])

    return inNavbarDrawer ? (
        <Box ref={ref} className={cn(css.inNavbarDrawer)}>
            {children}
        </Box>
    ) : (
        <>{children}</>
    )
}
