'use client'

import { useId } from 'react'

import cn from 'clsx'
import { Globe } from '@doist/icons/base'

import languageMapping, { type Languages } from './languages'

import css from './LanguagePicker.module.css'
import { Box } from '@/components/Box/Box'
import { SelectField } from '@/components/future/select-field/select-field'

export type LanguagePickerProps = {
    currentLanguage: Languages
    languages: Languages[]
    accessibilityText?: string
    onLangSelected?: (lang: Languages) => void
    useShortNames?: boolean
    className?: string
}

export function LanguagePicker({
    currentLanguage = 'en',
    languages = [],
    accessibilityText,
    onLangSelected = () => null,
    useShortNames = false,
    className,
    ...rest
}: LanguagePickerProps) {
    const selectId = useId()

    const languageMap = languages
        .sort()
        .map((lang) => ({ name: languageMapping[lang], value: lang }))

    return (
        <Box className={cn(className, { [css.useShortNames]: useShortNames })} {...rest}>
            <SelectField
                id={`language-picker-${selectId}`}
                aria-label={accessibilityText}
                size="sm"
                icon={<Globe aria-hidden />}
                value={currentLanguage}
                onChange={(e) => onLangSelected(e.currentTarget.value as Languages)}
            >
                {languageMap.map((lang) => (
                    <option key={lang.value} value={lang.value}>
                        {useShortNames ? lang.value : lang.name}
                    </option>
                ))}
            </SelectField>
        </Box>
    )
}
