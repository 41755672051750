'use client'

import { createContext, type ReactNode } from 'react'

import type { Themes } from '@/Theme'
import { todoist } from '@/Theme'

const ThemeContext = createContext<{ theme: Themes }>({ theme: todoist })

function ThemeProvider({ theme, children }: { theme: Themes; children: ReactNode }): JSX.Element {
    return <ThemeContext.Provider value={{ theme: theme }}>{children}</ThemeContext.Provider>
}

export { ThemeContext, ThemeProvider }
