import cn from 'clsx'

import { Box } from '@/components/Box/Box'
import type { BoxProps } from '@/components/Box/Box'

import css from '@/styles/accessibility.module.css'

export function VisuallyHidden({ className, children, ...rest }: BoxProps) {
    return (
        <Box className={cn(css.visuallyHidden, className)} {...rest}>
            {children}
        </Box>
    )
}
