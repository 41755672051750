import cn from 'clsx'

import { useNavbarContext } from '../navbar-context'

import css from './navbar-drawer.module.css'

export function NavbarOverlay() {
    const { isDrawerOpen, setIsDrawerOpen } = useNavbarContext()

    function handleClick() {
        if (isDrawerOpen) {
            setIsDrawerOpen(false)
        }
    }

    return (
        <div
            className={cn(css.overlay, { [css.visible]: isDrawerOpen })}
            onClick={handleClick}
            onKeyDown={handleClick}
        />
    )
}
