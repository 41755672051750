import type { CSSProperties } from 'react'

import { Box } from '@/components/Box/Box'
import type { ResponsiveProp } from '@/utils/responsiveProps'

import type { Breakpoints, Space } from '@/@types/styles'

import css from './Stack.module.css'

export type StackProps = {
    children: React.ReactNode
    tag?: 'div' | 'ol' | 'ul'
    gap?: ResponsiveProp<Space>
}

/**
 * Maps the gap property to the spacing CSS custom properties
 * The idea is to make setting stack easier with 4, 8, 32 etc as opposed to 'var(--space-4)'
 */
function mapGapToCSSProps(gap: StackProps['gap']): CSSProperties {
    const gapCustomProp = (value: string) => `var(--space-${value})`

    if (typeof gap === 'string') {
        return {
            '--stack': gapCustomProp(gap),
        } as CSSProperties
    }

    const mappedGap: { [key in Breakpoints as `--stack-${key}`]?: string } = {}

    if (gap) {
        for (const [size, space] of Object.entries(gap)) {
            mappedGap[`--stack-${size as Breakpoints}`] = gapCustomProp(space)
        }
    }

    return mappedGap as CSSProperties
}

export function Stack({ children, tag = 'div', gap }: StackProps) {
    const cssProps = mapGapToCSSProps(gap)

    return (
        <Box tag={tag} className={css.stack} style={cssProps}>
            {children}
        </Box>
    )
}
