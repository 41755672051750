import type { Breakpoints, Margins, Paddings, Space } from '@/@types/styles'

type Atom = string | number | boolean

// We want at least one size definition to exist otherwise, it'll be a problem
// https://learn.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest
type RequireAtLeastOne<T> = {
    [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export type ResponsiveProp<AtomType extends Atom> =
    | AtomType
    | Readonly<{ [key in Breakpoints]?: AtomType }>

export type ResponsivePropRequireOne<AtomType extends Atom> =
    | AtomType
    | Readonly<RequireAtLeastOne<{ [key in Breakpoints]?: AtomType }>>

/**
 * Builds a css module class name for a given prop + prop-value combination.
 *
 * We have a convention of building the internal utility-based class names system in a way that
 * resembles the prop for which it is used and the value of the prop. For instance, in a component
 * with a prop `width` with possible values `narrow` and `wide`, we encode the styles for each of
 * these alternatives in the class-names `.width-narrow` and `.width-wide`.
 *
 * Furthermore, this helper is aware of responsive prop values. For instance, if you provide the
 * `width` prop above with the value `['narrow', 'wide']` this returns `['narrow', 'tablet-wide']`.
 * That is, it returns an array of class names, following the same convention above, but also
 * prefixing by the viewport width variant (`tablet-` or `desktop-`).
 *
 * @param styles the class names mapping imported from a css module
 * @param property the prop name
 * @param value the given prop's value
 */
export function getClassNames(
    styles: Record<string, string>,
    property: string,
    value: ResponsiveProp<string> | null | undefined,
): string[] | null {
    if (!value) {
        return null
    }

    const classList: string[] = []

    if (typeof value === 'string') {
        classList.push(styles[`${property}-${value}`])
    } else {
        if (value.xs) classList.push(styles[`xs-${property}-${value.xs}`])
        if (value.sm) classList.push(styles[`sm-${property}-${value.sm}`])
        if (value.md) classList.push(styles[`md-${property}-${value.md}`])
        if (value.lg) classList.push(styles[`lg-${property}-${value.lg}`])
        if (value.xlg) classList.push(styles[`xlg-${property}-${value.xlg}`])
    }

    return classList
}

export function responsivePropToCSS(
    css: Record<string, string>,
    prop: string,
    value?: ResponsiveProp<string>,
) {
    const classNames: string[] = []
    const cssProps: Record<string, string> = {}

    if (value) {
        if (typeof value === 'string') {
            cssProps[`--${prop}`] = value
        } else {
            for (const [size, space] of Object.entries(value)) {
                cssProps[`--${prop}-${size as Breakpoints}`] = `${space}`
                classNames.push(css[`${prop}-${size}`])
            }
        }
    }
    return { classNames, cssProps }
}

export function getResponsiveSpaceClass(
    property: Margins | Paddings,
    value: ResponsiveProp<Space>,
): string[] | null {
    if (!value) {
        return null
    }

    const classList: string[] = []

    if (typeof value === 'string') {
        classList.push(`${property}-${value}`)
    } else {
        if (value.xs) classList.push(`${property}-${value.xs}`)
        if (value.sm) classList.push(`sm-${property}-${value.sm}`)
        if (value.md) classList.push(`md-${property}-${value.md}`)
        if (value.lg) classList.push(`lg-${property}-${value.lg}`)
        if (value.xlg) classList.push(`xlg-${property}-${value.xlg}`)
    }

    return classList
}

/**
 * A mapping over a responsive prop value.
 *
 * Since response values can be an object but also a scalar value, this helper makes it easier to
 * to map the values when it's an object but keeps it consistent for the case when it is a scalar
 * value as well.
 *
 * @param fromValue the responsive prop value
 * @param mapper the mapping function
 */
export function mapResponsiveProp<From extends Atom, To extends Atom>(
    fromValue: ResponsiveProp<From> | undefined,
    mapper: (from: From) => To,
): ResponsiveProp<To> | undefined {
    if (!fromValue) {
        return undefined
    }

    if (typeof fromValue !== 'object') {
        return mapper(fromValue)
    }

    return {
        xs: fromValue.xs ? mapper(fromValue.xs) : undefined,
        sm: fromValue.sm ? mapper(fromValue.sm) : undefined,
        md: fromValue.md ? mapper(fromValue.md) : undefined,
        lg: fromValue.lg ? mapper(fromValue.lg) : undefined,
        xlg: fromValue.xlg ? mapper(fromValue.xlg) : undefined,
    }
}
