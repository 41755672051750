'use client'

import { createContext, useContext } from 'react'

// @source: https://github.com/facebook/react/issues/14508#issuecomment-574444953
export const createRequiredContext = <TContext>(name: string) => {
    const Context = createContext<TContext>(undefined as unknown as TContext)
    const providerName = `${name}Provider`
    const hookName = `use${name}`

    Context.displayName = providerName

    const hook = () => {
        // This is a factory function for a custom hook; it will eventually be
        // run within a function component.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const context = useContext(Context)
        if (!context) {
            throw new Error(
                `${name} context not found. Please import ${providerName} and add it to a component above ${hookName}().`,
            )
        }
        return context
    }
    return [Context.Provider, hook] as const
}
