import { forwardRef } from 'react'

import cn from 'clsx'

import { Box } from '@/components/Box/Box'
import type { BoxProps } from '@/components/Box/Box'

import css from './Container.module.css'

// previous Twist sizes that were deprecated and their associated t-shirt size
// extraNarrow: xs, narrow: sm, wide: lg
type Sizes = 'xs' | 'sm' | 'base' | 'lg'

export interface ContainerProps extends Omit<BoxProps, 'borderRadius'> {
    width?: Sizes
    padded?: boolean
}

export const Container = forwardRef<HTMLElement, ContainerProps>(
    ({ width = 'base', padded = true, className, children, ...props }, ref) => {
        return (
            <Box
                className={cn(className, css.container, css[width], {
                    [css.noPadding]: !padded,
                })}
                ref={ref}
                {...props}
            >
                {children}
            </Box>
        )
    },
)

Container.displayName = 'Container'
