'use client'

import { type ReactNode, useState } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Container } from '@/components/Container/Container'

import { DropdownListItem } from './dropdown-list-item/dropdown-list-item'
import { NavbarDivider } from './navbar-divider/navbar-divider'
import { NavbarDrawControl } from './navbar-drawer/navbar-draw-control'
import { NavbarDrawer } from './navbar-drawer/navbar-drawer'
import { NavbarOverlay } from './navbar-drawer/navbar-overlay'
import { NavbarDropdown } from './navbar-dropdown/navbar-dropdown'
import { NavbarItem } from './navbar-item/navbar-item'
import { NavbarItemGroup } from './navbar-item-group/navbar-item-group'
import { NavbarLogo } from './navbar-logo/navbar-logo'
import { NavbarSection } from './navbar-section/navbar-section'
import { NavbarContextProvider } from './navbar-context'

import css from './navbar.module.css'

export type NavbarProps = {
    /** children should be NavbarSection components */
    children: ReactNode
    /** drawer should be a single NavbarDrawer */
    drawer?: ReactNode
    /** Banner should be a single NavbarBanner */
    banner?: ReactNode
    /** Bottom element is just below the navbar and stick to it. A perfect place for a progress bar for example. */
    bottomElement?: ReactNode
    layout?: 'even' | 'fluid'
    /** Progress */
    progressOnScroll?: boolean
}

export function Navbar({ children, drawer, banner, bottomElement, layout = 'fluid' }: NavbarProps) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    return (
        <NavbarContextProvider value={{ isDrawerOpen: isDrawerOpen, setIsDrawerOpen }}>
            <Box className={css.navbarWrapper}>
                <Box className={css.navbar}>
                    <Container
                        className={cn(css.navbarContainer, css[layout])}
                        tag="nav"
                        width="lg"
                    >
                        {children}
                    </Container>
                    {drawer}
                </Box>
                {bottomElement}
                {banner ? <Container className={css.bannerContainer}>{banner}</Container> : null}
            </Box>
            <NavbarOverlay />
        </NavbarContextProvider>
    )
}

Navbar.Divider = NavbarDivider
Navbar.Drawer = NavbarDrawer
Navbar.Dropdown = NavbarDropdown
Navbar.Item = NavbarItem
Navbar.ItemGroup = NavbarItemGroup
Navbar.Logo = NavbarLogo
Navbar.Section = NavbarSection
Navbar.DrawControl = NavbarDrawControl
Navbar.DropdownListItem = DropdownListItem
