'use client'

import { type AllHTMLAttributes, Fragment, useRef } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { ChevronDown, DoubleArrows } from '@doist/icons/brand'
import {
    BaseField,
    type BaseFieldLabelVariant,
    type BaseFieldProps,
} from '../base-field/base-field'

import css from './select-field.module.css'

type SelectProps = AllHTMLAttributes<HTMLSelectElement>

type ValidDropdownChildren = AllHTMLAttributes<HTMLOptionElement | HTMLOptGroupElement>

export type SelectFieldProps = Omit<BaseFieldProps, 'action' | 'variant'> &
    BaseFieldLabelVariant & {
        children: ValidDropdownChildren[] | ValidDropdownChildren
        value: NonNullable<SelectProps['value']>
        onChange: NonNullable<SelectProps['onChange']>
        onBlur?: SelectProps['onBlur']
        onFocus?: SelectProps['onFocus']
        placeholder?: SelectProps['placeholder']
        size?: 'base' | 'sm'
    }

export function SelectField({
    id,
    children,
    value,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    size = 'base',
    ...restProps
}: SelectFieldProps) {
    const inputRef = useRef<HTMLSelectElement | null>(null)

    return (
        <BaseField {...restProps} id={id} value={value} size={size}>
            {({ className, ...fieldProps }, icon) => {
                return (
                    <Fragment>
                        {icon}
                        <select
                            value={value}
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            className={cn([className, css.select, { [css.noValue]: !value }])}
                            {...fieldProps}
                            ref={inputRef}
                        >
                            <>
                                {!value || placeholder ? (
                                    <option value="" disabled={true}>
                                        {restProps.disabled ? '' : placeholder}
                                    </option>
                                ) : null}
                                {children}
                            </>
                        </select>
                        <Box tag="span" className={css.arrow} aria-hidden>
                            {size === 'sm' ? <DoubleArrows /> : <ChevronDown />}
                        </Box>
                    </Fragment>
                )
            }}
        </BaseField>
    )
}
