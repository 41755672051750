import { useEffect } from 'react'

import { Container } from '@/components/Container/Container'
import { Hidden } from '@/components/hidden/hidden'

import { useNavbarContext } from '../navbar-context'
import { NavbarOpenTransition } from '../navbar-open-transition/navbar-open-transition'

import { NavbarDrawerContext } from './navbar-drawer-context'

import css from './navbar-drawer.module.css'

type NavbarDrawerProps = {
    id?: string
    children: React.ReactNode
}

export function NavbarDrawer({ id, children }: NavbarDrawerProps) {
    const { isDrawerOpen, setIsDrawerOpen } = useNavbarContext()

    useEffect(() => {
        const mql = window.matchMedia('(max-width: 960px)')
        mql.addEventListener('change', (evt) => {
            if (isDrawerOpen && !evt.matches) {
                setIsDrawerOpen(false)
            }
        })
    }, [isDrawerOpen, setIsDrawerOpen])

    useEffect(() => {
        document.body.style.overflow = isDrawerOpen ? 'hidden' : ''
    }, [isDrawerOpen])

    return (
        <NavbarDrawerContext.Provider value={true}>
            <Hidden above="lg">
                <NavbarOpenTransition open={isDrawerOpen}>
                    <Container width="lg" className={css.navbarDrawer} id={id}>
                        {children}
                    </Container>
                </NavbarOpenTransition>
            </Hidden>
        </NavbarDrawerContext.Provider>
    )
}
