import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Hidden } from '@/components/hidden/hidden'

import css from './navbar-section.module.css'

type NavbarSectionProps = {
    children: React.ReactNode
    alignment?: 'start' | 'middle' | 'end'
    visibility?: 'mobile' | 'desktop' | 'both'
}

export function NavbarSection({
    children,
    alignment = 'start',
    visibility = 'both',
}: NavbarSectionProps) {
    return (
        <Hidden
            below={visibility === 'desktop' ? 'lg' : undefined}
            above={visibility === 'mobile' ? 'lg' : undefined}
        >
            <Box className={cn(css.navbarSection, css[alignment])}>{children}</Box>
        </Hidden>
    )
}
