import { useContext } from 'react'

import { ThemeContext } from '@/utils/themeContext'
import { Doist } from '@doist/icons/base'

import { NavbarSection } from '../navbar-section/navbar-section'

import css from './navbar-logo.module.css'
import { HyperLink } from '@/components/hyper-link/hyper-link'
import { TwistLogo } from '@/components/TwistLogo/TwistLogo'
import { TodoistLogo } from '@/components/TodoistLogo/TodoistLogo'

type NavbarLogoProps = {
    href?: string
    'aria-label': string
    headline?: {
        title: string
        href: string
    }
}

/**
 * Helper component for Product logos on the NavBar
 * Displays the theme related component
 * For anything more complex, use a custom <NavbarSection>
 */

export function NavbarLogo({ href = '/home', headline, ...props }: NavbarLogoProps) {
    return (
        <NavbarSection>
            <a href={href} aria-label={props['aria-label']}>
                <LogoMark preventSymbol={!headline} />
            </a>
            {headline && <Headline {...headline} />}
        </NavbarSection>
    )
}

type LogoMarkProps = { preventSymbol?: boolean }

function LogoMark({ preventSymbol = false }: LogoMarkProps) {
    const { theme = 'todoist' } = useContext(ThemeContext)

    if (theme === 'doist') {
        return <Doist width={59} height={33} />
    }

    if (theme === 'twist') {
        return <TwistLogo height={32} symbol={preventSymbol ? false : 'lt-md'} />
    }

    if (theme === 'todoist') {
        return <TodoistLogo height={32} symbol={preventSymbol ? false : 'lt-md'} />
    }

    return null
}

type HeadlineProps = { title: string; href: string }

function Headline({ title, href }: HeadlineProps) {
    return (
        <HyperLink className={css.headline} href={href}>
            {title}
        </HyperLink>
    )
}
