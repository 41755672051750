import type { CSSProperties } from 'react'

import cn from 'clsx'
import { Divider } from '@/components/Divider/Divider'
import { spaceToCustomProperty } from '@/utils/spaceToCustomProperty'

import type { Space } from '@/@types/styles'
import { useInNavbarDrawer } from '../navbar-drawer/navbar-drawer-context'

import css from './navbar-divider.module.css'

type Spacing = Space | number
type DividerSpacing = Spacing | [Spacing, Spacing]

type NavbarDividerProps = {
    spacing?: DividerSpacing
}

interface DividerCustomProperties extends CSSProperties {
    '--divider-spacing-start': string
    '--divider-spacing-end': string
}

/**
 * Divider needs fine grain spacing control based on the design
 * the spacing can accept one of the spacing values, a number
 * or a tuple of either value representing margin-inline-start
 * and margin-inline-end respectively
 *
 * This function converts the values to the correct css values
 * and sets the spacing
 *
 */
function convertSpaceToStyle(spacing: DividerSpacing): DividerCustomProperties {
    const [start, end] = Array.isArray(spacing) ? spacing : [spacing, spacing]

    return {
        '--divider-spacing-start':
            typeof start === 'number' ? `${Math.floor(start)}px` : spaceToCustomProperty(start),
        '--divider-spacing-end':
            typeof end === 'number' ? `${Math.floor(end)}px` : spaceToCustomProperty(end),
    }
}

export function NavbarDivider({ spacing = '24' }: NavbarDividerProps) {
    const inNavbarDrawer = useInNavbarDrawer()
    const customProps = convertSpaceToStyle(spacing)
    return (
        <Divider
            direction={inNavbarDrawer ? 'horizontal' : 'vertical'}
            semantic={false}
            className={cn(css.divider, { [css.inNavbarDrawer]: inNavbarDrawer })}
            style={customProps}
        />
    )
}
