'use client'

import cn from 'clsx'
import { ArrowRight, Play, NewTab, Download } from '@doist/icons/base'

import { HyperLink, type HyperLinkProps } from '@/components/hyper-link/hyper-link'

import css from './Link.module.css'
import type { Icon } from '@/components/Button/Button'

export interface LinkProps extends HyperLinkProps {
    icon?: 'arrow' | 'video' | 'external' | 'download' | Icon
    external?: boolean
}

/** A standard blue link */
export function Link({
    icon,
    className,
    children,
    external = false,
    target,
    size = 'inherit',
    ...rest
}: LinkProps) {
    let Icon = undefined
    switch (icon) {
        case 'arrow':
            Icon = ArrowRight
            break
        case 'video':
            Icon = Play
            break
        case 'external':
            Icon = NewTab
            break
        case 'download':
            Icon = Download
            break
        default:
            Icon = icon
    }

    return (
        <HyperLink
            className={cn(css.link, className)}
            target={external ? '_blank' : target}
            size={size}
            {...rest}
        >
            {Icon && <span className={css.icon}>{<Icon />}</span>}
            {children}
        </HyperLink>
    )
}
