import { forwardRef } from 'react'
import type { AllHTMLAttributes, ElementType, ReactNode } from 'react'

import cn from 'clsx'

import { getClassNames } from '@/utils/responsiveProps.ts'
import type { ResponsiveProp } from '@/utils/responsiveProps.ts'

import borderRadiusCss from '@/styles/responsiveBorderRadius.module.css'

interface BoxProps extends AllHTMLAttributes<HTMLElement> {
    tag?: ElementType
    children?: ReactNode
    className?: string
    borderRadius?: ResponsiveProp<'sm' | 'md' | 'lg'>
}

/** In replacement for `<div>` allowing props customization of the semantic HTML tag to render. */
const Box = forwardRef<HTMLElement, BoxProps>(
    ({ tag: Tag = 'div', borderRadius, children, className, ...rest }, ref) => {
        const borderRadiusClassNames = getClassNames(borderRadiusCss, 'borderRadius', borderRadius)

        return (
            <Tag className={cn(className, borderRadiusClassNames)} ref={ref} {...rest}>
                {children}
            </Tag>
        )
    },
)

Box.displayName = 'Box'

export { Box }
export type { BoxProps }
