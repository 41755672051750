import { type ComponentPropsWithoutRef, useId, useState } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Button, type ButtonProps, type MainVariant } from '@/components/Button/Button'
import { ChevronUp, ChevronDown } from '@doist/icons/brand'
import { useInNavbarDrawer } from '../navbar-drawer/navbar-drawer-context'
import { NavbarDropdown } from '../navbar-dropdown/navbar-dropdown'
import {
    NavbarDropdownContext,
    useInNavbarDropdown,
} from '../navbar-dropdown/navbar-dropdown-context'
import { NavbarOpenTransition } from '../navbar-open-transition/navbar-open-transition'

import css from './navbar-item.module.css'

type NativeAnchorProps = Omit<ComponentPropsWithoutRef<'a'>, 'classname'>
type NativeButtonProps = Omit<ComponentPropsWithoutRef<'button'>, 'classname'>

type DropdownListItemCommonProps = {
    variant?: MainVariant
    tag?: ButtonProps['tag']
    buttonClassname?: string
}

type DropdownListItemLabelProps = {
    label: React.ReactNode
    icon?: never
}

type DropdownListItemIconProps = {
    icon: ButtonProps['startIcon']
    label?: never
    'aria-label': string
}

type DropdownListItemLinkProps = NativeAnchorProps & {
    onClick?: never
    children?: never
}

type DropdownListItemButtonProps = NativeButtonProps & {
    onClick: () => void
    href?: never
    children?: never
}

type DropdownListDropdownProps = NativeAnchorProps & {
    href?: string
    onClick?: never
    children: React.ReactNode
}

type DropdownListItemProps = DropdownListItemCommonProps &
    (DropdownListItemLabelProps | DropdownListItemIconProps) &
    (DropdownListItemLinkProps | DropdownListItemButtonProps | DropdownListDropdownProps)

export function NavbarItem({
    label,
    variant = 'quaternary',
    href,
    onClick,
    children,
    tag = 'li',
    icon,
    buttonClassname,
    ...rest
}: DropdownListItemProps) {
    const inNavbarDrawer = useInNavbarDrawer()
    const inNavbarDropdown = useInNavbarDropdown()
    const isDropdownMenu = Boolean(children)
    const isNavbarDrawerDropdown = inNavbarDrawer && isDropdownMenu
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropdownId = useId()

    // Not something officially supported or tested yet
    if (inNavbarDropdown && isDropdownMenu) {
        console.warn('NavbarItem: Dropdowns cannot be nested')
    }

    function onClickHandler() {
        if (onClick) {
            onClick()
        }

        if (isDropdownMenu || isNavbarDrawerDropdown) {
            setIsDropdownOpen((value) => !value)
        }
    }

    function onBlurHandler(evt: React.FocusEvent<HTMLButtonElement>) {
        if (isDropdownMenu && isDropdownOpen && !inNavbarDrawer) {
            if (!evt.currentTarget.contains(evt.relatedTarget)) {
                setIsDropdownOpen(false)
            }
        }
    }

    return (
        <NavbarDropdownContext.Provider value={true}>
            <Box
                tag={tag}
                className={cn(css.navbarItem, {
                    [css.inNavbarDrawer]: inNavbarDrawer,
                    [css.inNavbarDropdown]: inNavbarDropdown,
                    [css.dropdownOpen]: isDropdownOpen,
                })}
                onBlur={isDropdownMenu ? onBlurHandler : undefined}
            >
                <Button
                    href={href}
                    onClick={!href ? onClickHandler : undefined}
                    variant={variant}
                    startIcon={icon ? icon : undefined}
                    endIcon={
                        isDropdownMenu
                            ? isDropdownOpen && isNavbarDrawerDropdown
                                ? ChevronUp
                                : ChevronDown
                            : undefined
                    }
                    size={inNavbarDrawer ? 'large' : 'base'}
                    aria-expanded={isNavbarDrawerDropdown ? isDropdownOpen : undefined}
                    aria-controls={isNavbarDrawerDropdown ? dropdownId : undefined}
                    className={cn(buttonClassname)}
                    {...rest}
                >
                    {label}
                </Button>

                {isDropdownMenu ? (
                    <NavbarOpenTransition open={isDropdownOpen}>
                        <NavbarDropdown id={dropdownId} className={css.navBarDropdown}>
                            {children}
                        </NavbarDropdown>
                    </NavbarOpenTransition>
                ) : null}
            </Box>
        </NavbarDropdownContext.Provider>
    )
}
