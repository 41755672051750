'use client'

import { useEffect } from 'react'
import type { DependencyList } from 'react'

import throttle from 'lodash/throttle'

/**
 * Execute code when the browser window is resized.
 *
 * Usage example:
 *
 * useThrottledResize(() => {
 *      drawSomething()
 *      return () => drawSomething()
 * }, [])
 *
 **/

export function useThrottledResize(
    wrapperFn: () => (evt: UIEvent) => void,
    dependencies: DependencyList = [],
) {
    // biome-ignore lint/correctness/useExhaustiveDependencies: test hangs with wrapperFn added
    useEffect(() => {
        let componentUnmounted = false

        const throttledFn = wrapperFn()
        const throttledHandleResize = throttle((resizeEvent) => {
            if (!componentUnmounted) throttledFn(resizeEvent)
        }, 150)

        window.addEventListener('resize', throttledHandleResize)
        return () => {
            window.removeEventListener('resize', throttledHandleResize)
            componentUnmounted = true
        }
    }, dependencies)
}
