import type { SVGProps } from 'react'

import cn from 'clsx'

import css from './TwistLogo.module.css'

// Twist Logo that will allow the words to be visible or not
// Be aware of that functionality before making changes

export interface TwistLogoProps extends SVGProps<SVGSVGElement> {
    variant?: 'primary' | 'monotone' | string
    symbol?:
        | boolean
        | 'lt-sm'
        | 'gt-sm'
        | 'lt-md'
        | 'gt-md'
        | 'lt-lg'
        | 'gt-lg'
        | 'lt-xlg'
        | 'gt-xlg'
}

export function TwistLogo({ symbol = false, variant = 'primary', ...rest }: TwistLogoProps) {
    return (
        <svg
            viewBox="0 0 99 32"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMid slice"
            fill={
                variant === 'primary'
                    ? '#008aa6'
                    : variant === 'monotone'
                      ? 'var(--display-onlight-primary)'
                      : variant
            }
            {...rest}
        >
            <title>Twist logo</title>
            <path d="M0 8.192c0-2.867 0-4.301.558-5.396A5.12 5.12 0 0 1 2.796.558C3.89 0 5.325 0 8.192 0h15.616c2.867 0 4.301 0 5.396.558a5.12 5.12 0 0 1 2.238 2.238C32 3.89 32 5.325 32 8.192v15.616c0 2.868 0 4.301-.558 5.397a5.12 5.12 0 0 1-2.238 2.237C28.11 32 26.675 32 23.808 32H8.192c-2.867 0-4.301 0-5.396-.558a5.12 5.12 0 0 1-2.238-2.237C0 28.109 0 26.675 0 23.808V8.192Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m0 6.272 9.869 2.5c.146.037.12.252-.032.252H0V6.272Zm17.635 3.732L4.66 11.843a.768.768 0 0 0-.66.76v4.98c0 .382.349.67.724.596l2.07-.404a.256.256 0 0 0 .207-.251L7 15.158a.8.8 0 0 1 .572-.768l10.246-3.045A.256.256 0 0 0 18 11.1v-.78a.32.32 0 0 0-.365-.316Zm-6.627 8.24c0-.59.337-1.127.868-1.383l11.371-5.488a.48.48 0 0 1 .689.432v4.95a1.6 1.6 0 0 1-.843 1.41l-2.691 1.69a.448.448 0 0 0-.18.218l-1.246 3.22c-.041.12-.189.172-.269.096l-1.82-1.46a.192.192 0 0 0-.203-.023l-4.922 2.345a.512.512 0 0 1-.754-.45v-5.557Z"
                fill="#fff"
            />
            <g
                className={cn({
                    [css.symbolOnly]: symbol === true,
                    [css.ltSm]: symbol === 'lt-sm',
                    [css.gtSm]: symbol === 'gt-sm',
                    [css.ltMd]: symbol === 'lt-md',
                    [css.gtMd]: symbol === 'gt-md',
                    [css.ltLg]: symbol === 'lt-lg',
                    [css.gtLg]: symbol === 'gt-lg',
                    [css.ltXlg]: symbol === 'lt-xlg',
                    [css.gtXlg]: symbol === 'gt-xlg',
                })}
            >
                <path
                    clipRule="evenodd"
                    d="M85.8 15.6c-.2 0-.5 0-.6-.4-.2-.4-.6-1-1.9-1-1.2 0-2 .5-2 1.4 0 1.2 1.8 1.2 3.6 1.7 1.9.5 3.8 1.3 3.8 3.9 0 2.1-1.6 4.2-5.6 4.2-4.8 0-5.4-3.1-5.4-3.6 0-.6.4-.7.7-.7h1.8c.2 0 .6.1.7.5.1.3.6 1.2 2.3 1.2.9 0 2.2-.3 2.2-1.3 0-1.3-1.6-1.6-3.3-1.9-2-.4-4.1-.9-4.1-3.8 0-2.3 1.8-4.2 5.2-4.2 4.2 0 5 2.9 5 3.1.1.4-.1.9-.7.9zM89.2 14v-1.4c0-.3.3-.7.7-.7h1.8V8.4c0-.4.2-.6.4-.7l1.9-.8c.6-.2 1 .2 1 .7v4.3h2.9c.5 0 .7.4.7.7V14c0 .4-.4.7-.7.7H95v6.2c0 1.4.8 1.5 1.5 1.5.2 0 .4 0 .5-.1.4-.1.8.1.9.3.2.4.5.9.6 1.2.2.4 0 .8-.3 1-.6.3-1.3.5-2.4.5-1.3 0-2.3-.2-3.2-1.1-.7-.7-.9-2-.9-3.8v-5.8h-1.8c-.5.1-.7-.3-.7-.6zM75.9 12.6c0-.4-.4-.7-.7-.7h-1.9c-.4 0-.7.3-.7.7v11.7c0 .4.3.7.7.7h1.9c.4 0 .7-.4.7-.7zM74.2 10.1c-.9 0-2-.6-2-1.9s1.1-1.9 2-1.9c.8 0 2 .6 2 1.9s-1 1.9-2 1.9z"
                    fillRule="evenodd"
                />
                <path d="M70.4 12h-1.9c-.3 0-.5.1-.6.4L65.8 20h-.1l-2.5-7.7c0-.1-.2-.3-.5-.3h-2.2c-.3 0-.5.1-.6.4l-2.5 7.7h-.1l-2.1-7.6c-.1-.3-.3-.4-.6-.4h-1.9c-.4 0-.7.4-.6.8l3.4 11.7c.1.4.5.7 1 .7h1.7c.4 0 .8-.3 1-.7l2.4-7.9 2.4 7.9c.1.4.5.7 1 .7h1.7c.4 0 .8-.3 1-.7L71 12.8c.1-.4-.2-.8-.6-.8z" />
                <path
                    clipRule="evenodd"
                    d="M41.9 14.1v-1.4c0-.3.3-.7.7-.7h1.8V8.5c0-.4.2-.6.4-.7.1 0 1.7-.7 1.7-.7.2-.1.3-.1.5-.1.4 0 .7.4.7.7V12h2.9c.5 0 .7.4.7.7v1.4c0 .4-.4.7-.7.7h-2.9v5.7c0 .7 0 1.2.2 1.6s.6.5 1.3.5c.2 0 .4 0 .5-.1.4-.1.8.1.9.3.2.4.5.9.6 1.2.2.4 0 .8-.3 1-.6.3-1.3.5-2.3.5-1.1 0-2.3-.1-3.3-1.1-.7-.7-1-1.7-1-3.8v-5.8h-1.8c-.4 0-.6-.4-.6-.7z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    )
}
