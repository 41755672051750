export type Languages =
    | 'ar'
    | 'cs'
    | 'da'
    | 'en'
    | 'de'
    | 'es'
    | 'fi'
    | 'fr'
    | 'it'
    | 'nl'
    | 'nb'
    | 'pl'
    | 'sv'
    | 'pt-BR'
    | 'ru'
    | 'tr'
    | 'zh-TW'
    | 'zh-CN'
    | 'ko'
    | 'ja'
    | 'psaccent'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const languageMapping: { [key in Languages]: string } = {
    ar: 'عربى',
    cs: 'Čeština',
    da: 'Dansk',
    en: 'English',
    de: 'Deutsch',
    es: 'Español',
    fi: 'Suomi',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    nb: 'Norsk',
    pl: 'Polski',
    sv: 'Svenska',
    'pt-BR': 'Português (Brazil)',
    ru: 'Pусский (Russian)',
    tr: 'Türkçe',
    'zh-TW': '中文 (繁體)',
    'zh-CN': '中文 (简体)',
    ko: '한국어',
    ja: '日本語',
    psaccent: 'Pseudolocalization',
}

export default languageMapping
