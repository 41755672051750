import type { ReactElement } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Stack } from '@/components/Stack/Stack'
import { Text } from '@/components/Text/Text'

import css from './field-label.module.css'

export type FieldLabelProps = {
    id?: string
    htmlFor?: string
    label?: string
    secondaryLabel?: string
    description?: string | ReactElement
    descriptionId?: string
    required?: boolean
}

export function FieldLabel({
    id,
    htmlFor,
    label,
    secondaryLabel,
    description,
    descriptionId,
    required = false,
}: FieldLabelProps) {
    return (
        <Box>
            <Stack gap="4">
                <label id={id} htmlFor={htmlFor} className={css.label}>
                    <Text tag="span" size="minor-base" weight="semibold">
                        {label}
                    </Text>

                    {required ? (
                        <Text
                            tag="span"
                            size="minor-base"
                            weight="semibold"
                            className={css.required}
                        >
                            *
                        </Text>
                    ) : null}

                    {secondaryLabel ? (
                        <Text tag="span" size="body-2xs" className={css.secondaryLabel}>
                            {secondaryLabel}
                        </Text>
                    ) : null}
                </label>

                {description ? (
                    <Box className={cn(css.description)} id={descriptionId}>
                        <Text tag="span" size="body-2xs">
                            {description}
                        </Text>
                    </Box>
                ) : null}
            </Stack>
        </Box>
    )
}
