import type { CSSProperties } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { spaceToCustomProperty } from '@/utils/spaceToCustomProperty'

import type { Space } from '@/@types/styles'
import { useInNavbarDrawer } from '../navbar-drawer/navbar-drawer-context'

import css from './navbar-item-group.module.css'

type NavbarItemGroupProps = {
    children: React.ReactNode
    gap?: Space | number
}

interface NavbarItemGroupCustomProperties extends CSSProperties {
    '--navbar-item-group-gap': string
}

export function NavbarItemGroup({ children, gap = 0 }: NavbarItemGroupProps) {
    const inNavbarDrawer = useInNavbarDrawer()

    const customProperties: NavbarItemGroupCustomProperties = {
        '--navbar-item-group-gap':
            typeof gap === 'number' ? `${Math.floor(gap)}px` : spaceToCustomProperty(gap),
    }

    return (
        <Box
            tag="ul"
            className={cn(css.navbarItemGroup, { [css.inNavbarDrawer]: inNavbarDrawer })}
            style={customProperties}
        >
            {children}
        </Box>
    )
}
