'use client'

import type { ReactNode } from 'react'
import { Warning, Check } from '@doist/icons/brand'
import cn from 'clsx'
import { Text } from '@/components/Text/Text'

import css from './field-message.module.css'

export type FieldMessageProps = {
    id: string
    message: ReactNode
    tone?: 'neutral' | 'critical' | 'positive'
}

export function FieldMessage({ id, message, tone = 'neutral' }: FieldMessageProps) {
    const Icon: Record<'critical' | 'positive', ReactNode> = {
        critical: <Warning />,
        positive: <Check />,
    }

    return (
        <Text size="body-2xs" className={cn(css.message, css[tone])} id={id}>
            {tone !== 'neutral' ? Icon[tone] : null}
            {message}
        </Text>
    )
}
