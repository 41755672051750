import { Box } from '@/components/Box/Box'
import { Text } from '@/components/Text/Text'
import { NavbarItem } from '../navbar-item/navbar-item'
import { Article } from '@doist/icons/base'
import css from './dropdown-list-item.module.css'

type DropdownListItemCommonProps = {
    href: string
    'aria-label'?: string
    icon?: React.ReactNode
    title: string
    description?: string
}

type DropdownListItemProps = DropdownListItemCommonProps

/**
 * Helper component that displays the list
 */
export function DropdownListItem({
    title,
    description,
    href,
    icon,
    ...rest
}: DropdownListItemProps) {
    const listIcon = icon ?? <Article width={18} height={18} />
    const ariaLabel = rest['aria-label'] ?? title

    return (
        <NavbarItem
            aria-label={ariaLabel}
            href={href}
            buttonClassname={css.dropdownListItemButton}
            label={
                <Box className={css.dropdownListItem}>
                    <Box className={css.iconContainer}>{listIcon}</Box>
                    <Box className={css.bodyContainer}>
                        <Text size="minor-base" weight="medium">
                            {title}
                        </Text>
                        <Text size="body-xs" weight="medium" className={css.description}>
                            {description}
                        </Text>
                    </Box>
                </Box>
            }
            {...rest}
        />
    )
}
