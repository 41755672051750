import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Button } from '@/components/Button/Button'

import Ruddy from './ruddy.png'
import Sunray from './sunray.png'

import css from './Divider.module.css'

type CommonProps = {
    direction?: 'vertical' | 'horizontal'
    className?: string
    /** @deprecated use variant instead */
    separator?: boolean
    style?: React.CSSProperties
    semantic?: boolean
}

type SeparatorVariantProps = {
    variant?: 'sunray' | 'ruddy'
    children?: never
    href?: never
}

type ButtonVariantProps = {
    variant: 'button'
    children: React.ReactText
    href: string
    /** direction not supported with button variant */
    direction?: never
}

export type DividerProps = CommonProps & (SeparatorVariantProps | ButtonVariantProps)

export function Divider({
    direction = 'horizontal',
    className,
    separator = false,
    variant = separator ? 'sunray' : undefined,
    href,
    children,
    style,
    semantic = true,
}: DividerProps) {
    if (variant === 'button') {
        return (
            <div className={cn(css.buttonDivider)}>
                <Button href={href} variant="tertiary">
                    {children}
                </Button>
            </div>
        )
    }

    return (
        <Box
            tag={semantic ? 'hr' : 'div'}
            aria-orientation={semantic ? direction : undefined}
            aria-hidden={!semantic ? true : undefined}
            className={cn([
                css.divider,
                css[direction],
                { [css.separator]: typeof variant === 'string' },
                className,
            ])}
            style={{
                backgroundImage:
                    variant === 'sunray'
                        ? `url(${Sunray})`
                        : variant === 'ruddy'
                          ? `url(${Ruddy})`
                          : undefined,
                ...style,
            }}
        />
    )
}
