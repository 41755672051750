import type { AllHTMLAttributes } from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import type { ResponsiveProp } from '@/utils/responsiveProps'

import css from './Text.module.css'

export type TextSize =
    | 'major-4xl'
    | 'major-3xl'
    | 'major-2xl'
    | 'major-xl'
    | 'major-lg'
    | 'major-base'
    | 'major-sm'
    | 'minor-xl'
    | 'minor-lg'
    | 'minor-base'
    | 'minor-sm'
    | 'minor-xs'
    | 'body-lg'
    | 'body-base'
    | 'body-sm'
    | 'body-xs'
    | 'body-2xs'
    | 'handwritten-base'
    | 'inherit'

interface CommonTextProps extends Omit<AllHTMLAttributes<HTMLElement>, 'size'> {
    tag?: React.ElementType
    children?: React.ReactNode
    style?: React.CSSProperties
    className?: string
}

type SizeGeneric<Sizes> = {
    size: Sizes
}

type BaseTextProps<Sizes> = SizeGeneric<Sizes> &
    CommonTextProps & {
        cssRules: Record<string, string>
    }

export type TextComponentProps<Sizes> = Partial<SizeGeneric<Sizes>> & CommonTextProps

export function BaseText<Sizes extends string>({
    tag = 'p',
    size,
    children,
    className,
    cssRules,
    ...rest
}: BaseTextProps<Sizes>) {
    return (
        <Box className={cn(css.text, cssRules[size], className)} tag={tag} {...rest}>
            {children}
        </Box>
    )
}

export interface TextProps extends TextComponentProps<TextSize> {
    borderRadius?: ResponsiveProp<'sm' | 'md' | 'lg'>
    weight?: 'bold' | 'semibold' | 'medium'
}

export function Text({ size = 'body-base', children, weight, className, ...rest }: TextProps) {
    return (
        <BaseText
            cssRules={css}
            className={cn(
                {
                    [css.bold]: weight === 'bold',
                    [css.semiBold]: weight === 'semibold',
                    [css.medium]: weight === 'medium',
                },
                className,
            )}
            size={size}
            {...rest}
        >
            {children}
        </BaseText>
    )
}
