import { Todoist } from '@doist/icons/base'
import { Container, Navbar, TwistLogo } from '@doist/marketist'

function Header() {
    return (
        <Container>
            <Navbar
                drawer={
                    <Navbar.Drawer>
                        <Navbar.ItemGroup gap={2}>
                            <Navbar.Item label="Products">
                                <Navbar.Item href="https://todoist.com/home" label="Todoist" />
                                <Navbar.Item href="https://twist.com/home" label="Twist" />
                            </Navbar.Item>
                            <Navbar.Item href="/about-us" label="About Us" />
                            <Navbar.Item href="/careers" label="Careers" />
                            <Navbar.Item href="/team" label="Team" />
                            <Navbar.Item href="/press" label="Press" />
                            <Navbar.Item href="/how-we-work" label="How we work" />
                        </Navbar.ItemGroup>
                    </Navbar.Drawer>
                }
            >
                <Navbar.Section>
                    <Navbar.Logo aria-label="Doist" href="/" />
                </Navbar.Section>

                <Navbar.Section alignment="end" visibility="desktop">
                    <Navbar.ItemGroup gap={2}>
                        <Navbar.Item label="Products">
                            <Navbar.DropdownListItem
                                icon={
                                    <Todoist
                                        width="24"
                                        height="24"
                                        color="var(--color-td-primary)"
                                    />
                                }
                                href="https://todoist.com/home"
                                title="Todoist"
                                description="The top-ranked productivity app that brings clarity and efficiency to millions of people and teams."
                            />
                            <Navbar.DropdownListItem
                                icon={<TwistLogo symbol width="24" height="24" />}
                                href="https://twist.com/home"
                                title="Twist"
                                description="The only asynchronous-first collaboration app that encourages teams to focus, create, and disconnect."
                            />
                        </Navbar.Item>
                        <Navbar.Item href="/about-us" label="About Us" />
                        <Navbar.Item href="/careers" label="Careers" />
                        <Navbar.Item href="/team" label="Team" />
                        <Navbar.Item href="/press" label="Press" />
                        <Navbar.Item href="/how-we-work" label="How we work" />
                    </Navbar.ItemGroup>
                </Navbar.Section>

                <Navbar.Section alignment="end" visibility="mobile">
                    <Navbar.ItemGroup>
                        <Navbar.DrawControl labelOpen="Open menu" labelClose="Close menu" />
                    </Navbar.ItemGroup>
                </Navbar.Section>
            </Navbar>
        </Container>
    )
}

export { Header }
