import cn from 'clsx'
import { Box } from '@/components/Box/Box'

import type { Breakpoints } from '@/@types/styles'

import css from './hidden.module.css'

import type { BoxProps } from '@/components/Box/Box'

export type HiddenProps = {
    children: React.ReactNode
    above?: Breakpoints
    below?: Breakpoints
    tag?: BoxProps['tag']
}

/**
 * Component that hides its children based on the given breakpoints.
 *
 * above='xlg' hides the children above screen-xlg
 * below='md' hides the children below screen-md
 */

export function Hidden({ children, above, below, tag }: HiddenProps) {
    return (
        <Box
            className={cn(css.hidden, {
                [css[`hidden-gt-${above}`]]: above,
                [css[`hidden-lt-${below}`]]: below,
            })}
            tag={tag}
        >
            {children}
        </Box>
    )
}
