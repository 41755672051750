'use client'

import { getRelsAndTarget } from '@/utils/get-rel-and-target'

import { Text } from '@/components/Text/Text'
import type { TextProps } from '@/components/Text/Text'

export type HyperLinkProps = TextProps & {
    tag?: React.ElementType
    href?: string
    aria?: string
    target?: HTMLAnchorElement['target']
}

export function HyperLink({
    children,
    aria,
    onClick,
    href = '#',
    tag = 'a',
    target,
    rel,
    ...rest
}: HyperLinkProps) {
    const onLinkClicked = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!onClick) return
        e.preventDefault()
        onClick(e)
    }
    const isLink = tag === 'a'
    let resolvedRel = rel || undefined
    let resolvedTarget = target || undefined
    if (!rel && isLink) {
        const { rels, target: proposedTarget } = getRelsAndTarget(href, resolvedTarget)
        resolvedRel = Array.from(rels).join(' ') || undefined
        resolvedTarget = proposedTarget
    }

    return (
        <Text
            tag={tag}
            aria-label={aria}
            onClick={onLinkClicked}
            href={tag === 'a' ? href : undefined}
            rel={resolvedRel}
            target={isLink ? resolvedTarget : undefined}
            {...rest}
        >
            {children}
        </Text>
    )
}
