'use client'

import {
    type AllHTMLAttributes,
    type CSSProperties,
    type ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react'

import cn from 'clsx'
import { Box } from '@/components/Box/Box'
import { Stack } from '@/components/Stack/Stack'
import type { TextProps } from '@/components/Text/Text'

import { FieldLabel, type FieldLabelProps } from '../field-label/field-label'
import { FieldMessage, type FieldMessageProps } from '../field-message/field-message'

import textStyles from '@/components/Text/Text.module.css'
import css from './base-field.module.css'

type FormElementProps = AllHTMLAttributes<HTMLFormElement>

type VisualLabel = {
    label?: FieldLabelProps['label']
    secondaryLabel?: FieldLabelProps['secondaryLabel']
    description?: FieldLabelProps['description']
}

type HiddenLabel = {
    'aria-label': string
    secondaryLabel?: never
    description?: never
}

export type BaseFieldLabelVariant = VisualLabel | HiddenLabel

export type BaseFieldProps = {
    id: FormElementProps['id']
    value?: FormElementProps['value']
    name?: FormElementProps['name']
    disabled?: FormElementProps['disabled']
    required?: boolean
    data?: Record<string, string | number>
    icon?: ReactNode
    action?: ReactNode
    message?: FieldMessageProps['message']
    tone?: FieldMessageProps['tone']
    variant?: 'fill' | 'outline'
}

interface FieldRenderProps extends Pick<BaseFieldProps, 'id' | 'name' | 'disabled' | 'required'> {
    'aria-describedby'?: string
    className: string
}

type InternalFieldProps = BaseFieldProps &
    BaseFieldLabelVariant & {
        children(props: FieldRenderProps, icon: ReactNode, action: ReactNode): ReactNode
        size?: 'base' | 'sm'
        textSize?: TextProps['size']
        draggable?: boolean
        isDragging?: boolean
    }

interface ActionElementProperties extends CSSProperties {
    '--action-element-width': string
}

function buildDataAttributes(data: BaseFieldProps['data'] = {}) {
    const attributes: BaseFieldProps['data'] = {}

    for (const key of Object.keys(data)) {
        attributes[`data-${key}`] = data[key]
    }

    return attributes
}

export function BaseField({
    id,
    value,
    name,
    disabled,
    required,
    data,
    children,
    icon,
    action,
    secondaryLabel,
    description,
    message,
    tone = 'neutral',
    variant = 'fill',
    size = 'base',
    textSize = 'body-sm',
    draggable = false,
    isDragging = false,
    ...rest
}: InternalFieldProps) {
    const messageId = `${id}-message`
    const descriptionId = `${id}-description`
    const $actionRef = useRef<HTMLDivElement | null>(null)
    const [actionWidth, setActionWidth] = useState(0)

    useEffect(() => {
        const width = $actionRef.current?.clientWidth || 0
        setActionWidth(width)
    }, [])

    return (
        <Box>
            <Stack gap="12">
                {'label' in rest ? (
                    <FieldLabel
                        htmlFor={id}
                        label={rest.label}
                        secondaryLabel={secondaryLabel}
                        description={description}
                        required={required}
                    />
                ) : null}
                <div
                    className={cn([
                        css.field,
                        css[variant],
                        css[`size-${size}`],
                        { [css.disabled]: disabled, [css.isDragging]: draggable && isDragging },
                    ])}
                    style={
                        { '--action-element-width': `${actionWidth}px` } as ActionElementProperties
                    }
                >
                    {children(
                        {
                            id,
                            name,
                            disabled,
                            required,
                            'aria-describedby': [messageId, descriptionId].join(' '),
                            ...('aria-label' in rest ? { 'aria-label': rest['aria-label'] } : {}),
                            ...buildDataAttributes(data),
                            className: cn([
                                css.input,
                                textStyles.text,
                                textStyles[size === 'sm' ? 'minor-sm' : textSize],
                                {
                                    [css.withIcon]: icon,
                                    [css.withAction]: action,
                                    [css.invalid]: tone === 'critical',
                                },
                            ]),
                        },
                        icon ? <div className={css.icon}>{icon}</div> : null,
                        action ? (
                            <div className={css.action} ref={$actionRef}>
                                {action}
                            </div>
                        ) : null,
                    )}
                </div>
                {message ? <FieldMessage id={messageId} message={message} tone={tone} /> : null}
            </Stack>
        </Box>
    )
}
