import cn from 'clsx'
import { Box } from '@/components/Box/Box'

import { useInNavbarDrawer } from '../navbar-drawer/navbar-drawer-context'

import { NavbarDropdownContext } from './navbar-dropdown-context'

import css from './navbar-dropdown.module.css'

type NavbarDropdownProps = {
    children: React.ReactNode
    className?: string
    id?: string
}

export function NavbarDropdown({ children, className, id }: NavbarDropdownProps) {
    const inNavbarDrawer = useInNavbarDrawer()

    return (
        <NavbarDropdownContext.Provider value={true}>
            <Box
                id={id}
                tag="ul"
                className={cn(
                    css.navbarDropdown,
                    { [css.inNavbarDrawer]: inNavbarDrawer },
                    className,
                )}
            >
                {children}
            </Box>
        </NavbarDropdownContext.Provider>
    )
}
