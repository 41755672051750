import type { CSSProperties } from 'react'

import { VisuallyHidden } from '@/components/VisuallyHidden/VisuallyHidden'

import css from './ActivityIndicator.module.css'

export type ActivityIndicatorProps = {
    size?: number
    strokeSize?: number
    strokeColor?: string
    fillColor?: string
    label?: string
}

interface IndicatorCSSProps extends CSSProperties {
    '--activityIndicatorSize': string
    '--activityIndicatorStrokeSize': string
    '--activityIndicatorStroke': string
    '--activityIndicatorFill': string
}

export function ActivityIndicator({
    size = 64,
    strokeSize = 4,
    strokeColor = 'var(--red-500)',
    fillColor = 'var(--color-grey-04)',
    label = 'Loading...',
}: ActivityIndicatorProps) {
    const customProps: IndicatorCSSProps = {
        '--activityIndicatorSize': `${size}px`,
        '--activityIndicatorStrokeSize': `${strokeSize}px`,
        '--activityIndicatorStroke': strokeColor,
        '--activityIndicatorFill': fillColor,
    }

    return (
        <div className={css.activityIndicator} style={customProps}>
            <VisuallyHidden>{label}</VisuallyHidden>
        </div>
    )
}
