import cx from 'clsx'
import { Text } from '@/components/Text/Text'

import css from './Badge.module.css'

export type BadgeProps = {
    children: React.ReactNode
    variant?: 'default' | 'new' | 'beta' | 'helpCenter'
    size?: 'base' | 'lg'
    className?: string
}

export function Badge({ children, className, variant = 'default', size = 'base' }: BadgeProps) {
    return (
        <Text
            tag="span"
            size={size === 'lg' ? 'minor-sm' : 'body-2xs'}
            className={cx(css.badge, css[variant], css[size], className)}
        >
            {children}
        </Text>
    )
}
