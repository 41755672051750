const trustedDomains = ['todoist.com', 'todoist.help', 'doist.com', 'twist.com']

export function getRelsAndTarget(
    href: string,
    defaultTarget?: HTMLAnchorElement['target'],
): { rels: Set<string>; target?: HTMLAnchorElement['target'] } {
    const rels = new Set<string>()

    let target: HTMLAnchorElement['target'] | undefined = defaultTarget

    if (href.startsWith('http')) {
        try {
            const url = new URL(href)
            const isTrustedDomain = trustedDomains.some((domain) => url.hostname.endsWith(domain))
            if (!isTrustedDomain) {
                rels.add('nofollow')
                if (!target) target = '_blank'
            } else if (url.pathname.startsWith('/app')) {
                rels.add('nofollow')
            }
        } catch (e) {
            console.error(`Error parsing ${href}`, e)
        }
    }

    if (target === '_blank') {
        rels.add('noopener')
        rels.add('noreferrer')
    }

    return { rels, target }
}
