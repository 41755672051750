'use client'

import {
    type AllHTMLAttributes,
    type ComponentType,
    type CSSProperties,
    type ReactElement,
    type ReactNode,
    useContext,
    useState,
} from 'react'

import cn from 'clsx'
import type { Languages } from '@/components/LanguagePicker/languages'
import { TwistLogo } from '@/components/TwistLogo/TwistLogo'
import { Badge } from '@/components/Badge/Badge'
import { Button } from '@/components/Button/Button'
import { Container } from '@/components/Container/Container'
import { HyperLink } from '@/components/hyper-link/hyper-link'
import { LanguagePicker } from '@/components/LanguagePicker/LanguagePicker'
import { Text } from '@/components/Text/Text'
import { ThemeContext } from '@/utils/themeContext'
import { TodoistLogo } from '@/components/TodoistLogo/TodoistLogo'
import { useThrottledResize } from '@/utils/useThrottledResize'
import { doist, todoist, twist } from '@/Theme'
import { ChevronRight } from '@doist/icons/brand'
import { Doist } from '@doist/icons/base'
import {
    SocialFacebook,
    SocialInstagram,
    SocialLinkedin,
    SocialTwitter,
    SocialYoutube,
    Rss,
} from '@doist/icons/base'
import css from './Footer.module.css'

const HOME_ALT_TEXT = 'Homepage'
const FACEBOOK_DEFAULT_ALT_TEXT = 'Subscribe on Facebook'
const TWITTER_DEFAULT_ALT_TEXT = 'Follow on Twitter'
const YOUTUBE_DEFAULT_ALT_TEXT = 'Watch on YouTube'
const INSTAGRAM_DEFAULT_ALT_TEXT = 'Follow on Instagram'
const LINKEDIN_DEFAULT_ALT_TEXT = 'Follow on LinkedIn'
const LANGUAGE_ACCESSIBILITY_TEXT = 'Select language'
const RSS_DEFAULT_ALT_TEXT = 'Subscribe to RSS'

export type FooterLink = {
    title: string
    href: string
    badge?: string
}

export type FooterColumn = {
    title: string
    icon?: ReactNode
    links: FooterLink[]
}

export type SocialLink = {
    service: 'twitter' | 'facebook' | 'youtube' | 'instagram' | 'rss' | 'linkedin'
    url: string
    altText?: string
}

export type FooterProps = {
    /** A custom logo, override the one provided by the Theme prop. */
    logo?: ComponentType
    /** The logo link. */
    homeLink?: string
    /** The aria label of the logo link. */
    homeAlternateText?: string
    /** The footer title. */
    title?: ReactElement<typeof Text>
    /** Right sided column links. */
    columnLinks?: FooterColumn[]
    /** Bottom links. */
    bottomLinks?: FooterLink[]
    /** The current language the website is displayed. */
    currentLanguage?: Languages
    /** Shows the language dropdown with supported languages. */
    supportedLanguages?: Languages[]
    /** The language dropdown accessibility text. */
    languageAccessibilityText?: string
    /** Callback when a user select a language. */
    onLangSelected?: (lang: Languages) => void
    /** The footer background color. */
    backgroundColor?: string
    /** The bottom bar background color. */
    bottomBarBackgroundColor?: string
    /** The top separator color, if empty no hairline is shown. */
    hairlineBackgroundColor?: string
    variant?: 'socialColumn'
    socialLinks?: SocialLink[]
}

interface TopContainerProps extends CSSProperties {
    '--hairline-color': string
}

export function Footer({
    homeLink = '/',
    logo,
    homeAlternateText = HOME_ALT_TEXT,
    title,
    columnLinks = [],
    bottomLinks = [],
    currentLanguage = 'en',
    supportedLanguages = [],
    languageAccessibilityText = LANGUAGE_ACCESSIBILITY_TEXT,
    onLangSelected = () => null,
    backgroundColor = 'var(--color-grey-02)',
    bottomBarBackgroundColor = 'rgba(0, 0, 0, 0.05)',
    hairlineBackgroundColor = 'transparent',
    style = {},
    className,
    variant,
    socialLinks = [],
    ...rest
}: FooterProps & Omit<AllHTMLAttributes<HTMLDivElement>, 'title'>): JSX.Element {
    const { theme = 'doist' } = useContext(ThemeContext)
    const isSocialColumnVariant = variant === 'socialColumn'

    const topContainerCustomProps: TopContainerProps = {
        '--hairline-color': hairlineBackgroundColor,
    }

    const [hideArrowIcons, setHideArrowIcons] = useState(false)

    useThrottledResize(() => {
        function checkHideArrowIcons() {
            setHideArrowIcons(window.innerWidth >= 769)
        }
        checkHideArrowIcons()
        return checkHideArrowIcons
    }, [])

    return (
        <footer
            className={cn(className, css.footer, css[theme], {
                [css.socialColumn]: isSocialColumnVariant,
            })}
            style={{ backgroundColor, ...style }}
            {...rest}
        >
            <Container width="lg" className={cn(css.topSection)} style={topContainerCustomProps}>
                <div className={cn(css.header, { [css.customLogo]: logo })}>
                    {(logo || theme) && (
                        <HyperLink
                            href={homeLink}
                            aria-label={homeAlternateText}
                            className={css.logoLink}
                            size="inherit"
                        >
                            <LogoMark backgroundColor={backgroundColor} logo={logo} />
                        </HyperLink>
                    )}
                    {title ? title : null}
                    {isSocialColumnVariant ? null : <SocialLinks socialLinks={socialLinks} />}
                </div>

                <div className={css.columnLinks}>
                    {columnLinks.map((column) => (
                        <div className={css.columnLinkGroup} key={column.title}>
                            {column.icon && isSocialColumnVariant ? (
                                <div className={css.columnIcon}>{column.icon}</div>
                            ) : null}
                            <Text size="minor-base" weight="semibold" className={css.subheading}>
                                {column.title}
                            </Text>
                            <ol className={css.columnLinksContainer}>
                                {column.links.map((link) => (
                                    <li className={css.columnLinkItem} key={link.href}>
                                        {/* 
                                            Manually include the icon instead of using Button's icon prop because it's hidden at large screen sizes 
                                            and the icon property doesn't support that scenario 
                                        */}
                                        <Button
                                            href={link.href}
                                            variant="quaternary"
                                            className={css.columnLink}
                                            endIcon={
                                                isSocialColumnVariant && !hideArrowIcons
                                                    ? ChevronRight
                                                    : undefined
                                            }
                                            slotLayout={
                                                link.badge ? (
                                                    <Badge className={css.badge} variant="new">
                                                        {link.badge}
                                                    </Badge>
                                                ) : undefined
                                            }
                                        >
                                            {link.title}
                                        </Button>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    ))}
                    {isSocialColumnVariant ? <SocialLinks socialLinks={socialLinks} /> : null}
                </div>
            </Container>

            <div
                className={css.bottomSection}
                style={{ backgroundColor: bottomBarBackgroundColor, ...style }}
            >
                <Container className={css.bottomSectionContainer} width="lg" padded>
                    <div className={css.bottomLinks}>
                        <ul className={css.bottomLinkList}>
                            {bottomLinks.map((link) => (
                                <li key={link.href}>
                                    <HyperLink size="body-2xs" href={link.href}>
                                        {link.title}
                                    </HyperLink>
                                </li>
                            ))}
                        </ul>
                        <Text className={css.copyright} size="body-2xs">
                            © Doist Inc.
                        </Text>
                    </div>

                    {supportedLanguages.length > 0 && (
                        <div className={css.supportedLanguages}>
                            <LanguagePicker
                                currentLanguage={currentLanguage}
                                languages={supportedLanguages}
                                onLangSelected={onLangSelected}
                                accessibilityText={languageAccessibilityText}
                            />
                        </div>
                    )}
                </Container>
            </div>
        </footer>
    )
}

function LogoMark({
    backgroundColor,
    logo: Logo,
}: {
    backgroundColor: string
    logo?: ComponentType
}) {
    const { theme = 'doist' } = useContext(ThemeContext)

    if (Logo) {
        return <Logo />
    }

    if (theme === doist) {
        return <Doist width={59} height={33} />
    }

    if (theme === twist) {
        return <TwistLogo height={32} symbol="lt-md" className={css.logo} />
    }

    if (theme === todoist) {
        return (
            <TodoistLogo
                height={32}
                variant="monotone"
                tickOverride={backgroundColor}
                symbol="lt-md"
                className={css.logo}
            />
        )
    }

    return null
}

export function SocialLinks({ socialLinks }: { socialLinks: SocialLink[] }): JSX.Element {
    return (
        <div className={css.socialLinksContainer}>
            <ul className={css.socialLinks}>
                {socialLinks.map((link) => (
                    <li key={link.url}>
                        <Button
                            className={css.socialLinkButton}
                            size="large"
                            href={link.url}
                            variant="quaternary"
                            aria-label={socialAlt(link)}
                        >
                            <SocialIcon service={link.service} />
                        </Button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function SocialIcon({ service }: { service: SocialLink['service'] }) {
    switch (service) {
        case 'facebook':
            return <SocialFacebook aria-hidden />
        case 'instagram':
            return <SocialInstagram aria-hidden />
        case 'rss':
            return <Rss aria-hidden />
        case 'twitter':
            return <SocialTwitter aria-hidden />
        case 'youtube':
            return <SocialYoutube aria-hidden />
        case 'linkedin':
            return <SocialLinkedin aria-hidden />
        default:
            return null
    }
}

function socialAlt(socialLink: SocialLink) {
    if (socialLink.altText) {
        return socialLink.altText
    }

    switch (socialLink.service) {
        case 'facebook':
            return FACEBOOK_DEFAULT_ALT_TEXT
        case 'instagram':
            return INSTAGRAM_DEFAULT_ALT_TEXT
        case 'rss':
            return RSS_DEFAULT_ALT_TEXT
        case 'twitter':
            return TWITTER_DEFAULT_ALT_TEXT
        case 'youtube':
            return YOUTUBE_DEFAULT_ALT_TEXT
        case 'linkedin':
            return LINKEDIN_DEFAULT_ALT_TEXT
        default:
            return ''
    }
}
