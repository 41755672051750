import { Button } from '@/components/Button/Button'
import { useNavbarContext } from '../navbar-context'
import { X, Menu } from '@doist/icons/brand'

type NavbarDrawerControlProps = {
    labelOpen: string
    labelClose: string
}

export function NavbarDrawControl({ labelOpen, labelClose }: NavbarDrawerControlProps) {
    const { isDrawerOpen, setIsDrawerOpen } = useNavbarContext()

    return (
        <Button
            variant="quaternary"
            startIcon={isDrawerOpen ? X : Menu}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            aria-label={isDrawerOpen ? labelClose : labelOpen}
        />
    )
}
